@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('./../fonts/Roboto-Regular.ttf') format('truetype');
    font-display: swap;
}
html, body{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    background: #F9FAFC;

    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;

    overflow: hidden auto !important;

}

a{
    text-decoration: none;
}

#root{
    width: 100%;
    height: 100%;
}



/* these make sure it can work in any text element */
.inline-text_copy--active,
.inline-text_input--active {
    font: inherit;
    color: inherit;
    text-align: inherit;
    padding: 0;
    background: none;
    border: none;
    outline: none;
}

.inline-text-multiline{
    white-space: pre-line;
}

.inline-text_copy--active {
    cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
    display: none;
}

.inline-text_input--active {
    background: #fff;
    border: 1px solid #10B16E;
    border-radius: 4px;
    margin: auto;
    padding: 2px 24px;
    text-align: end;
}
.cell{
    display: grid;
    text-align: end;
    align-items: center;
    border-bottom: 1px solid #c0c0c063;
    margin-bottom: -1px;
    padding-right: 8px;
}

sup.positiveColor{
    color:#04A842;
}
sup.negativeColor{
    color:#A80404;
}

.daily-table {
    border-spacing: 0;
    /*border-collapse: collapse;*/
}
tr:hover  td{
    cursor: pointer;
}

.daily-table tr > td {
    /*width: 84px;*/
    text-align: end;
    padding-right: 6px;
    height: 20px;
    max-width: 90px;
    min-width: 90px;
    box-sizing: border-box;
}

tr.week-total td{
    background-color: rgba(153, 153, 153, 0.35);
}
.daily-table tr.week-total td.revenue-bg {
    background-color: rgb(222, 231, 234);
}
.daily-table tr.week-total td.negative-bg {
    background-color: #ead0c8;
}
.daily-table tr.week-total td.neutral-bg {
    background-color: #e6eab2;
}
.daily-table tr.week-total td.predictive-bg {
    background-color: #d9e6ea;
}
.daily-table tr.week-total td.positive-bg {
    background-color: #b0e2c3;
}
.daily-table tr.week-total td.positive-bg {
    background-color: #b0e2c3;
}
.daily-table tr.week-total td.subscriptions {
    background-color: #c8e5d3;
}
.daily-table tr.week-total td.subscriptions-trial {
    background-color: #dceae2;
}
.daily-table tr.week-total td.payments {
    background-color: #ace2df;
}
.daily-table tr.week-total td.payments-trial {
    background-color: #92e4d9;
}
.daily-table tr.week-total td.spend{
    background-color: #eaddbb;
}


.daily-table tr:hover td {
    background-color: rgba(0, 0, 0, 0.13);
}
.daily-table tr:hover td.revenue-bg {
    background-color: rgb(212, 221, 224);
}
.daily-table tr:hover td.negative-bg {
    background-color: #e0c7c0;
}
.daily-table tr:hover td.neutral-bg {
    background-color: #dce0a8;
}
.daily-table tr:hover td.predictive-bg {
    background-color: #cedbdf;
}
.daily-table tr:hover td.positive-bg {
    background-color: #a1d3b4;
}
.daily-table tr:hover td.positive-bg {
    background-color: #a7d9ba;
}
.daily-table tr:hover td.subscriptions {
    background-color: #bedbc9;
}
.daily-table tr:hover td.subscriptions-trial {
    background-color: #d2e0d8;
}
.daily-table tr:hover td.payments {
    background-color: #ace2df;
}
.daily-table tr:hover td.payments-trial {
    background-color: #92e4d9;
}
.renewals-report tr:hover{
    background-color: #e1e1e1;
}

.daily-table tr:hover td.spend{
    background-color: #e0d3b1;
}

td.revenue-bg {
    background-color: #F0F9FC;
}
td.predictive-bg{
    background-color: #E6F3F7;
}
td.positive-bg{
    background-color: #BAECCD
}
td.negative-bg{
    background-color: #FAE0D8
}
td.neutral-bg{
    background-color: #F6FAC2
}
td.subscriptions {
    background-color: #D2EFDD;
}
td.subscriptions-trial {
    background-color: #E9F7EF;
}
td.payments {
    background-color: #BAF0ED;
}
td.payments-trial {
    background-color: #9EF0E5;
}
td.border-right{
    border-right: 1px solid #c0c0c063;
}
tr.border-bottom > td {
    border-bottom: 1px solid #c0c0c063;
}

td.spend{
    background-color: #F9ECCA;
}
td > div:not(.edit) {
    padding-right: 8px;
}
td > sup {
    margin-right: -2px;
}

.testIndicator.active { background-color: #E8F8F1; color:rgba(16, 177, 110, 1)}
.testIndicator.inactive { background-color: #F8E8E8; color:rgba(177, 16, 16, 1)}

.dot{
    height: 6px;
    width: 6px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.dot.active { background-color:rgba(16, 177, 110, 1)}
.dot.inactive { background-color:rgba(177, 16, 16, 1)}

.multilineTextInput > div.MuiOutlinedInput-multiline {
    padding: 8.5px 14px;
}